import { useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import './CollapsibleText.css';

function CollapsibleText({ buttonText, content }) {
  const [open, setOpen] = useState(false);
  const [rotate, setRotate] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
    setRotate(!rotate);
  };

  return (
    <>
      <Button
        variant="link"
        onClick={handleToggle}
        aria-expanded={open}
        className="custom-button px-0"
      >
        <i 
          className={`fa-solid fa-chevron-down transition ${rotate ? 'rotate' : ''}`}
        ></i>
        <strong className='button-text'>{buttonText}</strong>
      </Button>

      <Collapse in={open}>
        <div className='text-start' id="collapse-text" dangerouslySetInnerHTML={{ __html: content }} />
      </Collapse>
    </>
  );
}

export default CollapsibleText;

