
import koulutukset from '../../../assets/images/koulutukset.jpg'
import { Container, Row, Col, Image } from 'react-bootstrap'
import './Form.css'


function Form() {

    return (
        <Container fluid id='ota-yhteytta' className='container-form'>
            <Row>
                <Col className="d-lg-none d-flex justify-content-center p-0">
                    <Image
                        src={koulutukset}
                        className="header-image-form"
                    />
                </Col>
            </Row>
            <Row>
                <Col className="left-content-form d-lg-flex d-none flex-column align-items-center">
                    <h2 className='form-h2 text-center'>Ota yhteyttä</h2>
                        <iframe className="iframe-ota-yhteytta" scrolling="no" bgcolor="#fffcf7"
                            src="https://9fyi8wqd.sendsmaily.net/landing-pages/ca708dad-1060-4faa-b43b-c5e19763dbe1/html/"
                            title="Subscription Form"
                        />
                </Col>
                <Col className="d-lg-flex d-none justify-content-lg-end p-0">
                    <Image
                        src={koulutukset}
                        className="header-image-form"
                    />
                </Col>
            </Row>
            <Row>
                <Col className="d-lg-none d-flex flex-column justify-content-center align-items-center text-center">
                    <h2 className="form-h2">Ota yhteyttä</h2>
                        <iframe className="iframe-ota-yhteytta" scrolling="no" bgcolor="#fffcf7"
                            src="https://9fyi8wqd.sendsmaily.net/landing-pages/ca708dad-1060-4faa-b43b-c5e19763dbe1/html/"
                            title="Subscription Form"
                        />
                </Col>
            </Row>
        </Container>
    )
}

export default Form