import { Col, Container, Row } from "react-bootstrap"
import './Contact.css'
import { Link } from "react-router-dom"


function Contact() {
    return (
<Container className="contact-container">
    <Row>
        <Col xs={12} md={12} lg={6} className="contact-left-content text-lg-start text-center">
            <h2 className="contact-h2">Seuraa Brändihuvilaa Instagramissa</h2>
            <div className="ig-div d-flex justify-content-center align-items-center flex-column">
                <Link to="https://www.instagram.com/brandihuvila" target="_blank" rel="noopener noreferrer">
                    <i className="instagram-icon fa-brands fa-instagram fa-10x"></i>
                </Link>
            </div>
        </Col>
        <Col xs={12} md={12} lg={6} className="contact-right-content text-lg-start text-center">
    <h2 className="contact-h2">Tilaa Brändihuvilan uutiskirje</h2>
    <div className="iframe-div d-flex justify-content-center align-items-center">
        <iframe className="iframe-contact" scrolling="no" bgcolor="#fffcf7"
            src="https://9fyi8wqd.sendsmaily.net/landing-pages/77993cb7-51c2-4784-8c62-91a828f6aa19/html/"
            title="Subscription Form"
        />
    </div>
</Col>
    </Row>
</Container>
    )
}

export default Contact