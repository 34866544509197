import { Row, Col, Container } from "react-bootstrap"
import './Brandilupaukset.css'
import LupausBlock from "./LupausBlock"
import hourglass from '../../../assets/images/hourglass.png'
import iceberg from '../../../assets/images/iceberg.png'
import lamp from '../../../assets/images/lamp.png'








function Brandilupaukset() {
    return (
        <section id="brandilupaukset">
            <Container fluid className="brandilupaukset-container mt-5">
                <Row className="d-flex align-items-center">
                    <h2 className="brandilupaukset-h2 text-center d-lg-none p-0 m-0">Olisimmeko oikea apu yrityksenne brändin rakentamiseen? Näin toimimme:</h2>
                    <Col className="d-none d-lg-flex justify-content-start text-center">
                        <h2 className="brandilupaukset-h2 mt-3 mb-0">Olisimmeko oikea apu yrityksenne brändin rakentamiseen? Näin toimimme:</h2>
                    </Col>
                </Row>
                <div className="container mt-5">
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xs={12} md={12} lg={4} className="d-flex text-center align-items-center justify-content-center">
                            <LupausBlock
                                image={hourglass}
                                title={
                                    <>
                                        Projekteillamme on <span style={{ color: '#38b6ff' }}>selkeä</span> aikataulu, suunnitelma ja päämäärä
                                    </>
                                }
                                description={
                                    <>
                                        Valitse vain teidän yritykselle sopivin paketti - olemme suunnitelleet kaiken valmiiksi.
                                    </>
                                }
                                price="3000€/kk + alv 25,5%"
                                buttonText="Lue lisää"
                                centerImageTop={false}
                                link="#brandimatka"
                            />
                        </Col>
                        <Col xs={12} md={12} lg={4} className="d-flex align-items-center justify-content-center text-center">
                            <LupausBlock
                                image={iceberg}
                                title="Teemme brändityötä, joka ei jää pintapuoliseksi"
                                description={
                                    <>
                                        Keskitymme brändityössä sanallistamaan sen, mitä merkitystä juuri teidän brändi tuo asiakkaiden elämään. Miksi teihin halutaan sitoutua?
                                    </>
                                }
                                price="1500€/kk + alv 25,5%"
                                buttonText="Lue lisää"
                                link="#brandistrategi"
                            />
                        </Col>
                        <Col xs={12} md={12} lg={4} className="d-flex align-items-center justify-content-center text-center">
                            <LupausBlock
                                image={lamp}
                                title={
                                    <>
                                        Haluamme tehdä <span style={{ color: '#38b6ff' }}>teistä</span> brändäyksen ammattilaisia
                                    </>
                                }
                                description={
                                    <>
                                        Tavoitteenamme on tehdä meistä tarpeettomia yhteistyömme aikana. Sillä te, yrityksen sisällä, olette brändinne parhaita asiantuntijoita.
                                    </>}
                                price="Hinta sovitaan erikseen."
                                buttonText="Pyydä tarjous"
                            />
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default Brandilupaukset