import Brandilupaukset from '../../components/ui/Brandilupaukset/Brandilupaukset'
import Brandimatka from '../../components/ui/Brandimatka/Brandimatka'
import Brandistrategi from '../../components/ui/Brandistrategi/Brandistrategi'
import Branditarina from '../../components/ui/Branditarina/Branditarina'
import Contact from '../../components/ui/Contact/Contact'
import Footer from '../../components/ui/Footer/Footer'
import Form from '../../components/ui/Form/Form'
import Header from '../../components/ui/Header/Header'
import Hero from '../../components/ui/Hero/Hero'
import NavBar from '../../components/ui/NavBar/NavBar'
import PageSplitter from '../../components/ui/PageSplitter/PageSplitter'
import Services from '../../components/ui/Services/Services'
import TopBar from '../../components/ui/TopBar/TopBar'



function Home() {
    return (
        <>
            <TopBar />
            <NavBar />
            <Header />
            <Hero />
            <PageSplitter />
            <Services />
            <Brandimatka />
            <Brandistrategi />
            <PageSplitter />
            <Branditarina />
            <Brandilupaukset />
            <Contact />
            <Form />
            <Footer />
        </>
    )
}

export default Home
