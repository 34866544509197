import { Navbar, Nav, Container, Image } from 'react-bootstrap'
import logomusta from '../../../assets/images/logomusta.png'
import './NavBar.css'
import { HashLink } from 'react-router-hash-link'
import { useState } from 'react'





function NavBar() {
  const [expanded, setExpanded] = useState(false);
  return (
    <Navbar fluid expanded={expanded} expand="lg" sticky='top' className='d-lg-none navbar-border'>
      <Container>
        <Navbar.Brand href="/"><Image src={logomusta} className='navbar-image img-fluid' style={{ height: '20px', width: 'auto' }} /></Navbar.Brand>
        <Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          <HashLink onClick={() => setExpanded(false)} className='nav-item-navbar' to="#palvelumme">Palvelumme</HashLink>
          <HashLink onClick={() => setExpanded(false)} className='nav-item-navbar' to="#tarina">Tutustu meihin</HashLink>
          <HashLink onClick={() => setExpanded(false)} className='nav-item-navbar' to="#ota-yhteytta">Ota yhteyttä</HashLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar