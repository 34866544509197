import kahvikuva from '../../../assets/images/kahvikuva.jpg'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { HashLink } from 'react-router-hash-link'
import './Hero.css'

function Hero() {

    return (
        <Container fluid className="hero">
            <Row className="align-items-center">
                <Col className="hero-left-content d-lg-flex d-none">
                    <Image
                        src={kahvikuva}
                        className='hero-image mx-auto' />
                </Col>
                <Col className="hero-right-content hero-text-mobile">
                    <h2 className='hero-text-h2 d-lg-flex d-none'>Kaiken takana on...</h2>
                    <p className='hero-text-p'>
                        <strong>Jenni</strong>, Brändihuvilan perustaja, omistaja sekä teidän yrityksenne oma, <strong>vieraileva brändistrategi</strong>. 
                    </p>
                    <p className='hero-text-p'> Ajatus Brändihuvilan perustamisesta syntyi puhtaasta intohimosta brändäystä kohtaan.
                         Opiskelin markkinointia Oulun yliopistossa ja tutkin opintojeni aikana huippuarvosanoin mm. toimitusjohtajan henkilöbrändäystä sekä 
                         brändiaktivismin vaikutuksia yritysbrändeihin. Aika mehukkaita aiheita!
                    </p>
                    <p className='hero-text-p'>
                    Koko opintojeni ajan työskentelin suomalaisessa kasvuyrityksessä erilaisissa rooleissa 
                    aina markkinoinnista kumppaniverkoston hallintaan ja johtamiseen. Teoriaa tutkiessa ja käytännön työtä seuratessa ymmärsin,
                     että brändin rakentaminen on koko liiketoiminnan ydin. Ja onhan se myös äärimmäisen mielenkiintoista - ja koukuttavaa.
                    </p>
                    <p className='hero-text-p'>
                        Niinpä syntyi ajatus Brändihuvilasta. <strong>Paikasta,</strong> jossa voin puhua lempiaiheestani, brändäyksestä, joka ikinen päivä.
                        <strong> Visiosta,</strong> jossa suomalaiset tunnetaan tulevaisuudessa brändäyksen asiantuntijoina.
                        <strong> Yrityksestä,</strong> jonka kautta pääsen auttamaan pieniä suomalaisia yrityksiä, joista on kasvamassa jotain suurta.
                    </p>
                    <p className='hero-signature'>Jenni</p>
                    <div className='d-flex align-items-center justify-content-center'>
                        <HashLink to="#tarina" className='tutustu-button btn btn-primary'>
                            Tutustu<br />brändihuvilaan
                        </HashLink>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Hero