import './Header.css'
import kahvikuva from '../../../assets/images/kahvikuva.jpg'
import video from '../../../assets/videos/finalvideo.mp4'
import logomusta from '../../../assets/images/logomusta.png'
import { HashLink } from 'react-router-hash-link';
import { Container, Row, Col, Image } from 'react-bootstrap'
import kahvitarina from '../../../assets/images/kahvitarina.jpg'


function Header() {

    return (
        <Container fluid>
            <Col className="logocol d-none d-lg-block">
                <Image
                    src={logomusta}
                    alt="Brändihuvila"
                    className="logo-image"
                />
            </Col>
            <Row className="align-items-center">
                <Col className="left-content text-center">
                    <h1 className="main-title mobile-top-space mobile-font-size-h1">
                        Lämpimästi<br className="d-none d-lg-block" /> tervetuloa<br />Brändihuvilalle!
                    </h1>
                    <p className="sub-title-mobile d-lg-none mt-3">Uuden aikakauden bränditoimisto</p>
                    <div className='d-lg-flex d-none justify-content-center'>
                        <HashLink className='nav-item' to="#palvelumme">Palvelumme</HashLink>

                        <HashLink className='nav-item' to="#tarina">Tutustu meihin</HashLink>

                        <HashLink className='nav-item' to="#ota-yhteytta">Ota yhteyttä</HashLink>
                    </div>


                </Col>
                <Col className="right-content d-none d-lg-flex justify-content-end align-items-center p-0">
                    <div className="text-image-container">
                        <p className="loop-animation sub-title m-0">Uuden aikakauden bränditoimisto</p>
                        <video
                            src={video}
                            loop
                            autoPlay
                            muted
                            className="header-video"
                            playsInline
                        ></video>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className='d-flex d-lg-none justify-content-center align-items-center p-0 m-0'>
                    <Image
                        src={kahvitarina}
                        className='kahvitarina-image-header'
                    />
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col className="left-content text-center">
                    <h2 className='mobile-font-size-h2 after-video-title d-lg-none'>
                        Kaiken takana on...
                    </h2>
                </Col>
                <Col className='right-content'>
                    <Image
                        src={kahvikuva}
                        className='d-lg-none header-image' />
                </Col>
            </Row>
        </Container>
    )
}

export default Header

