import { Col, Container, Row, Image } from "react-bootstrap"
import { HashLink } from "react-router-hash-link"
import strategi2 from '../../../assets/images/strategi2.jpg'
import CollapsibleText from "../CollapsibleText/CollapsibleText"
import './Brandistrategi.css'





function Brandistrategi() {

    return (
        <Container id="brandistrategi" fluid>
            <Row>
                <Col className='d-flex d-lg-none justify-content-center align-items-center'>
                    <Image
                        src={strategi2}
                        alt="Brandistrategi"
                        className="strategi2-image"

                    />
                </Col>
            </Row>
            <Row>
                <Col className="left-content-brandistrategi d-none d-lg-flex p-0 m-0">
                    <Image
                        src={strategi2}
                        alt="strategi2"
                        className="strategi2-image"
                    />
                </Col>
                <Col className="right-content-brandistrategi d-flex flex-column align-items-lg-end text-lg-end align-items-center text-center">
                    <h2 className="brandistrategi-h2 mt-1">Vieraileva brändistrategi</h2>
                    <p className="brandistrategi-price-text">Hinta 1500€/kk + alv 25,5%, kesto min. 3kk</p>
                    <p className="brandistrategi-text-p">Brändin johtaminen on yksi yrityksen kriittisimpiä tehtäviä. Silti edelleen monesta yrityksestä puuttuu brändiasiantuntija, ja tähän ongelmaan haluamme tarjota ratkaisun. Yrityksenne oma, vieraileva brändistrategi kulkee yrityksenne matkassa juuri niin kauan kuin tarvetta on. Palvelun avulla ette maksa turhasta: saatte täsmällisesti apua ainoastaan yrityksenne brändin kehittämiseen ja johtamiseen.</p>


                    <p className="brandistrategi-read-more-text">Tästä pääset lukemaan lisää:</p>
                    <div className="collapsible-container-brandistrategi align-items-lg-start align-items-center">
                        <CollapsibleText buttonText="Mitä palveluun sisältyy?" content="Yksinkertaisesti: käytössänne on koko kuukauden ajan yrityksenne oma, vieraileva brändistrategi.<Br />
                        <Br />
                        Palveluun sisältyy:<Br />
<Br />
<li>Jatkuva yrityksen markkinointiviestinnän seuranta sekä brändituki sähköpostitse</li>
<li>Kuukausittainen brändipalaveri (1x2h per kk), jossa keskitymme brändin kehittämiseen ja johtamiseen. Kuukausipalaverissa käymme
läpi yrityksenne kuluneen kuukauden brändin näkökulmasta sekä kirkastamme seuraavan kuukauden toimenpiteet ja tavoitteet.</li>" />
                        <CollapsibleText buttonText="Kuinka kauan yhteistyö kestää?" content="Brändityötä ei tehdä kiireessä tai nopeasti, ja siksi yhteistyön minimikesto on kolme kalenterikuukautta.
                         Tämän jälkeen yhteistyötä on mahdollista jatkaa toistaiseksi voimassaolevana niin pitkään kuin tarvetta on. 
<Br />
<Br />
Yhteistyön seuraava mahdollinen aloitus on 1.1.2025." />
                        <CollapsibleText buttonText="Kuinka paljon palvelu kustantaa?" content="Palvelun hinta on 1500€ + alv 25,5% per kuukausi. Yhteistyön minimikesto on kolme kuukautta. Laskutus tapahtuu aina jälkikäteen, kuun lopussa.
                        <Br/>
                        <Br/>
                        Tarjoamme tyytyväisyystakuun yhteistyölle ensimmäisen kuukauden ajan. Jos siis ensimmäisen kuukauden aikana ette ole tyytyväisiä palveluun, voidaan yhteistyö aina peruuttaa ja tällöin laskutamme teiltä 0€." />
                        <CollapsibleText buttonText="Miten yhteistyömme etenee?" content="Kun olet meihin yhteydessä lomakkeen kautta tai sähköpostitse, etenemme näin:
<Br/><Br/>
1. Maksuton kartoitustapaaminen Teams-yhteydellä<Br/>
2. Yhteistyösopimuksen allekirjoittaminen sähköisesti<Br/>
3. Kuukausipalaverien aikataulun sopiminen<Br/>
4. Nähdään kuukausipalaverissa!<Br/>
<Br/><Br/>
Kuukausipalaverissa käymme läpi yrityksenne kuluneen kuukauden brändin näkökulmasta sekä kirkastamme seuraavan kuukauden toimenpiteet ja tavoitteet.
" />
                    </div>
                    <div className="d-flex align-items-center justify-content-center w-100 mt-5">
                        <HashLink to="#ota-yhteytta" className="btn btn-primary brandistrategi-button brandistrategi-button1">Olen kiinnostunut</HashLink>
                        <HashLink to="#tarina" className="btn btn-primary brandistrategi-button">Tutustu lisää Brändihuvilaan</HashLink>
                    </div>
                </Col>
            </Row>

        </Container>
    )
}

export default Brandistrategi