import { Image } from "react-bootstrap"
import viiva from '../../../assets/images/viiva.png'


function PageSplitter() {



    return (
        <Image fluid
            src={viiva}
        />
    )
}

export default PageSplitter