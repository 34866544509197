import './App.css'
import Home from './pages/Home/Home'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/tietosuoja-ja-rekisteriseloste" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
