import './TopBar.css'
import { Container, Row, Col } from 'react-bootstrap';

function TopBar() {
    return (
        <div className="topBar text-center">
        <Container fluid>
            <Row>
                <Col className="loop-animation2">
                    <span className="topBarText">
                        Bookkaamme nyt vuotta 2025
                    </span>
                </Col>
            </Row>
        </Container>
    </div>
    )
}

export default TopBar
