import Statement from '../../components/ui/Statement/Statement'



function PrivacyPolicy() {
    return (
        <>
           <Statement /> 
        </>
    )
}

export default PrivacyPolicy